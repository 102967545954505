import { message, Modal, Table } from "antd";
import React, { useEffect, useState } from "react";
import { burialPoint, OnlineVedioApi } from "@/api";
import { v4 as uuid } from "uuid";
import OnlineVedio from "../OnlineVedio";
function OnlineVedioModal(props) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isShowOnlineVedio, setOnlineVedio] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [url, setUrl] = useState("");
  const [isModalOpenLook, setIsModalOpenLook] = useState(false);
  const { deviceSerialId, deviceChannelNo, vid, warehouse, title } = props;
  const [id, setId] = useState(uuid());
  const [timer, setTimer] = useState(null);
  const [Isplaying, setIsplaying] = useState(false);
  const [openTime, setopenTime] = useState("");
  let ChildRef_bank = React.createRef();

  useEffect(() => {
    setopenTime(new Date().getTime());
    getVedio();
    setIsplaying(false);
    return () => {
      setOnlineVedio(false);
      if (timer) {
        clearTimeout(timer);
      }
    };
  }, [vid]);

  // 获取可绑定list
  const getVedio = async () => {
    const p = {
      deviceChannelNo,
      deviceSerialId,
    };
    let {
      data: { accessToken, video },
    } = await OnlineVedioApi(p);
    if (!accessToken || !video) {
      message.warning("摄像头已离线");
      setIsModalVisible(false);
      return false;
    }
    sessionStorage.setItem(
      "cameraInfo",
      JSON.stringify({
        pageName: "驾驶舱-监控",
        name: "cameraView",
        viewTime: new Date().getTime(),
        attr: {
          device: deviceSerialId,
          channel: deviceChannelNo,
          warehouse: warehouse || title || "",
        },
      })
    );
    setIsModalVisible(true);

    setAccessToken(accessToken);
    setUrl(video);
    setOnlineVedio(true);
    setId(uuid());
  };
  const look = () => {
    setIsModalOpenLook(true);
  };
  const handleOk = () => {
    setIsModalOpenLook(false);
  };
  const handleCancelLook = () => {
    setIsModalOpenLook(false);
  };

  const handleCancel = async () => {
    console.log("handlecancel", props);

    sessionStorage.setItem("cameraInfo", JSON.stringify({}));
    await burialPoint("驾驶舱", openTime, 0, {
      platform: "PC",
      pageName: "驾驶舱-监控",
      name: "cameraView",
      attr: {
        device: deviceSerialId,
        channel: deviceChannelNo,
        warehouse: warehouse || title || "",
      },
    });
    try {
      console.log(ChildRef_bank.current.stopVedio());
    } catch (error) {
      console.log("handlecancel", error);
    }

    const timerOne = setTimeout(() => {
      setOnlineVedio(false);
      setIsModalVisible(false);
    }, 300);
    setTimer(timerOne);
  };
  // destroyOnClose = { true}
  return (
    <div>
      <Modal
        open={isModalVisible}
        destroyOnClose={true}
        style={{ top: 20 }}
        width={1020}
        footer={false}
        onCancel={handleCancel}
      >
        <div style={{ marginTop: 15, position: "relative" }}>
          {isShowOnlineVedio && (
            <OnlineVedio
              onRef={ChildRef_bank}
              uuid={id}
              accessToken={accessToken}
              url={url}
              setIsplaying={setIsplaying}
            ></OnlineVedio>
          )}

          {Isplaying && props.mosaic && (
            <div
              style={{
                marginTop: 48,
                position: "absolute",
                top: 0,
                zIndex: 999999,
              }}
            >
              <img
                src={props.mosaic}
                // onClick={look}
                style={{
                  height: 452,
                  width: 970,
                }}
              ></img>
            </div>
          )}
          {Isplaying && props.pic && (
            <div style={{ marginTop: 10 }}>
              参照图：
              <img
                src={props.pic}
                onClick={look}
                style={{
                  height: 45,
                  width: 45,
                }}
              ></img>
            </div>
          )}
        </div>
      </Modal>
      {isModalOpenLook && (
        <Modal
          title=""
          open={isModalOpenLook}
          onOk={handleOk}
          footer={null}
          width={1000}
          onCancel={handleCancelLook}
        >
          <img
            src={props.pic}
            style={{
              textAlign: "center",
              height: "450px",
              width: "95%",
            }}
          ></img>
        </Modal>
      )}
    </div>
  );
}

export default OnlineVedioModal;
